import {store} from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class TaxPayrollService{
    getTaxPayrollList(param){
        const url = `api/tax/payroll/filter`
        const params = {
            'page-index': param.size,
            'branch':param.branch,
            'searched':param.searched,
            'from':param.from,
            'to':param.to,
            'offset':param.offset,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getTaxPayrollElements(){
        const url = `api/tax/payroll/elements`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    getTaxReceiptNo(){
        const url = `api/tax/payroll/receipt/no`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    createTaxPayroll(formdata){
        const url = `api/tax/payroll/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formdata,headers);
    }
    editTaxPayroll(id,formdata){
        const url = `api/tax/payroll/update/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formdata,headers);
    }
    cancelTaxPayroll(id){
        const url = `api/tax/payroll/cancel/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
}
export default new TaxPayrollService();