<template>
  <div class="content-body">
    <div class="row mt-2 mt-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Account</a></li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Tax Payroll
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Tax Payroll </h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          type="button"
          v-if="checkSinglePermission('create_pay_in')"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', { id: 'newTaxPayroll', mode: 'create' })
          "
        >
          New Tax Payroll
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head no-print pt-0">
      <div class="row">
        <div class="filter-input col-md-10 d-block mr-0 pr-0">
          <select v-model="params.size" @change="filterDatas" class="mr-2 mg-t-5" id="pay_in_filter_size">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <div class="mr-2 d-inline-block">
            <label class="filter-lavel mg-t-5  mb-0 mr-1">From</label>
            <input type="date" v-model="params.from" placeholder="From" class="filter-input mg-t-5  mr-1" id="pay_in_filter_date_from" @change="filterDatas">
            <label class="filter-lavel mg-t-5  mb-0 mr-1">To</label>
            <input type="date" v-model="params.to" placeholder="To" class="mr-1 filter-input mg-t-5 " id="pay_in_filter_date_to" @change="filterDatas">
          </div>
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Receipt No</th>
              <th class="wd-10p">Type</th>
              <th class="wd-20p">Tax Title</th>
              <th class="wd-15p">Payment Mode</th>
              <th class="wd-10p">Amount.</th>
              <th class="wd-10p text-center table-end-item no-print" v-if="checkIfAnyPermission(['view_tax_payroll','cancel_tax_payroll'])">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && taxDatas.length > 0">
            <tr v-for="(tax,index) in taxDatas" :key="index">
              <th scope="row" class="table-start-item">{{pageSerialNo+index}}.</th>
              <td>{{tax.date}}</td>
              <td>{{tax.receipt_no}} <span class="tx-danger" v-if="tax.status == 1">-Canceled </span></td>
              <td>{{(tax.tax_type==1)?'Outgoing':'Incoming'}} </td>
              <td>{{tax.tax_field.title}}</td>
              <td>{{tax.payment_method.title}} <span v-if="tax.bank!= null">-{{tax.bank.bank}}</span></td>
              <td>{{parseDigitForSlip(tax.amount)}}</td>
              <td class="text-right table-end-item no-print" v-if="checkIfAnyPermission(['view_tax_payroll','cancel_tax_payroll','edit_tax_payroll'])">
                <a href="javascript:;" class="mr-3"  title="View Slip" v-if="checkSinglePermission('view_tax_payroll')">
                  <i class="fas fa-eye"  @click="view('taxSlip',tax.id)"></i>
                </a>
                 <a href="javascript:;" class="mr-3"  title="Edit Tax Payroll" v-if="tax.status==0 && checkSinglePermission('edit_tax_payroll')">
                  <i class="fas fa-edit"  @click="view('editTaxPayroll',tax.id)"></i>
                </a>
                <a href="javascript:;" class="mr-3" v-if="tax.status==0 && checkSinglePermission('cancel_tax_payroll')" title="Cancel Tax Payroll">
                  <i class="fas fa-times-circle tx-danger"  @click="cancel(tax.id)"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td  colspan="11" style="text-align:center;">
               {{error_message}}
              </td>
            </tr>
          </tbody>
            <tbody v-else-if="!loading && taxDatas.length == 0">
            <tr>
              <td  colspan="11" style="text-align:center;">
                No Records Found.
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td  colspan="11" style="text-align:center;">
                Loading.....
              </td>
            </tr>
          </tbody>
          
        </table>
        <Paginate v-model="page" :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1" />
      </div>
    </div>
    <Create @parent-event="getData" />
    <Edit @parent-event="getData" />
    <Slip />
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Create from "./create";
import Paginate from 'vuejs-paginate'
import Slip from "./slip";
import Edit from "./edit";
import Services from "./Services/Services";
import _ from 'lodash';
export default {
 //mixins: [Search],
  data(){
    return{
      params :{
        size:10,
        branch:'',
        account_type:'',
        today:'',
        month:'',
        from:'',
        searched:'',
        to:"",
        offset:0,
        status:'',
      },
      loading:true,
      page:1,
      error:false,
      error_message:'',
      //pagination required
      totalPageCount:0,
      pageReload:'',
      pageSerialNo:1,
    }
  },
  components: {
    Paginate,Create,Slip,Edit
  },
   computed: {
    ...mapGetters([
      "icons",
      "authData",
      "dataId",
      "dataLists",
      "dataLists2",
      "today",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("taxPayrolls",["taxDatas","taxElements"])
  },
  mounted() {
    this.getData();
  },
  methods: {
    //pagination method
    clickCallback: function(page) {
      console.log(page)
      if(page == 1){
        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.size) + 1 ;
      }
      this.params.offset = (page-1) * this.params.size;
      this.getData();
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    view(modal,id){
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal,mode:"read"});
    },
    filterDatas: _.debounce(function(){
      this.loading = true;
      this.page = 1;
      this.params.offset = 0;
      this.pageSerialNo = 1;
      this.disableSubmitButton=true;
      Services.getTaxPayrollList(this.params).then(res=>{

          this.$store.commit("taxPayrolls/setTaxDatas",res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading=false;
      }).catch(err=>{
          if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
          } else {
            this.error_message = "Error Fetching data from the server.";
          }
          this.error = true;
          this.loading=false;
          let error = err.response.data.error_message;
          this.setNotification(error);
      });
    },1000),
    cancel(id) {
      this.$swal({
       title: 'Do you really want to cancel this Tax Payroll ?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Cancel it!',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        preConfirm:() => {
           return Services.cancelTaxPayroll(id).then((response) => {
              this.taxDatas.forEach((item,index) => {
              if(item.id == id){
                    this.taxDatas[index]['status'] = 1;
                    this.taxDatas[index]['user']['name'] = this.authData.user.name;
                    this.taxDatas[index]['cancel_date'] = this.today;
                }              
              });
          if(response.status == 200){
              let success = response.data.success_message;
              this.setNotification(success);
            }
          }).catch(error => { 
            this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if(error.response.status == 500){
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
          })
        }
      })
    },
    setNotification(msg){
        console.log(msg)
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
    getData(){
      this.loading =true;
      Services.getTaxPayrollList(this.params).then(res=>{
          this.$store.commit("taxPayrolls/setTaxDatas",res.data.data.data);
          this.getPayInElements();
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading= false;
      }).catch(err=>{
        if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
          } else {
            this.error_message = "Error Fetching data from the server.";
          }
        this.error = true;
        this.loading=false;
      })
    },
    getPayInElements(){
        Services.getTaxPayrollElements().then((res) => {
          this.$store.commit("taxPayrolls/setTaxElements", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>