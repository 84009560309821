<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'editTaxPayroll'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Edit Tax Payroll
            </h5>
            <a
              class="close"
              title="Close"
              @click="$store.dispatch('modalClose', 'newTaxPayroll')"
            >
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Receipt No.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.receipt_no"
                              class="form-control"
                              id="loan_id"
                              placeholder="id"
                              disabled
                            />
                          </div>
                        </div>
                         <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Date *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input type="date" class="form-control" name="date" v-model="formData.date">
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Tax Type *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                              id="tax_type"
                              v-model="formData.tax_type"
                              required
                            >
                              <option value="1">Outgoing</option>
                              <option value="0">Incoming</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Tax Title *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                              id="loan_account_name"
                              v-model="formData.title_id"
                              required
                            >
                              <option value selected disabled>
                                Select Tax Title
                              </option>
                              <option
                                v-for="(tax, index) in taxElements.tax_titles"
                                :key="index"
                                :value="tax.id"
                              >
                                {{ tax.title }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Amount Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Tax Amount *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              step="any"
                              min="1"
                              v-model="formData.amount"
                              class="form-control"
                              id="tax_amount"
                              placeholder="NRP 0.00"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Payment Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Branch *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.branch_id"
                              class="form-control"
                              id="loan_branch_type"
                              required
                              :disabled="branchDisabled"
                              @change="getPayments"
                            >
                              <option value selected disabled>
                                Select Branch
                              </option>
                              <option
                                v-for="(method, index) in taxElements.branchs"
                                :key="index"
                                :value="method.id"
                              >
                                {{ method.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Payment Method *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.payment_method_id"
                              class="form-control"
                              id="loan_payment_method"
                              required
                              @change="getBanks"
                            >
                              <option value selected disabled>
                                Select Method
                              </option>
                              <option
                                v-for="(method, index) in payement_methods"
                                :key="index"
                                :value="method.id"
                              >
                                {{ method.title }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Bank Name *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.bank_id"
                              class="form-control"
                              id="loan_bank_name"
                              required
                              :disabled="bank_disabled"
                            >
                              <option value selected disabled>
                                Select Bank
                              </option>
                              <option
                                v-for="(bank, index) in banks"
                                :key="index"
                                :value="bank.id"
                              >
                                {{ bank.bank }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Ref.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="input"
                              v-model="formData.ref"
                              class="form-control"
                              id="loan_transaction_id"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    

                    <div class="group" id="description">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              id="loan_description"
                              type="text"
                              v-model="formData.description"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary"
                  :disabled="disableSubmitButton"
                >
                  {{ disableSubmitButton ? "Submitting..." : "Submit" }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
export default {
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists1",
      "dataId",
      "modalId",
      "modalMode",
      "today",
    ]),
    ...mapGetters("taxPayrolls", ["taxDatas", "taxElements"]),
  },
  data() {
    return {
      formData: {
        receipt_no: "",
        tax_type: 1,
        title_id: "",
        payment_method_id: "",
        bank_id: "",
        branch_id: "",
        ref: null,
        amount: null,
        description: null,
      },
      disableSubmitButton: false,
      bank_disabled: true,
      payement_methods: [],
      banks: [],
      branchDisabled: false,
    };
  },
  methods: {
    submit() {
      this.disableSubmitButton = true;
      Services.editTaxPayroll(this.formData.id,this.formData)
        .then((res) => {
          this.$store.commit("taxPayrolls/setTaxDatas", res.data.data.data);
          this.$store.commit("setDataId", res.data.data.id);
          this.$store.dispatch("modalWithMode", {
            id: "taxSlip",
            mode: "read",
          });
          this.$emit("parent-event");
        })
        .catch((err) => {
          if (err.response.status == 422) {
            let error = Object.values(err.response.data.errors).flat();
            this.setNotification(error);
          } else {
            let error = err.response.data.error_message;
            this.setNotification(error);
          }
        })
        .finally(() => {
          this.disableSubmitButton = false;
        });
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    getPayments() {
      this.bank_disabled = true;
      this.formData.bank_id = "";
      if (this.taxElements.payment_methods.length != 0) {
        let details = [];
        let methods = this.formData.branch_id;
        details = this.taxElements.payment_methods.filter(function (data) {
          return data.branch == methods;
        });
        this.payement_methods = { ...details };
        this.formData.payment_method_id = this.payement_methods[0].id;
      }
    },
    getBanks() {
      let payment_bank = this.formData.payment_method_id;
      let values = this.taxElements.payment_methods.find(function (data) {
        if (data.id == payment_bank) {
          return data.title;
        }
      });
      if (values.title.toLowerCase() == "bank") {
        if (this.taxElements.banks.length != 0) {
          this.bank_disabled = false;
          let details = [];
          let methods = this.formData.branch_id;
          details = this.taxElements.banks.filter(function (data) {
            return data.branch == methods;
          });
          this.banks = { ...details };
          if (details.length > 0) {
            this.formData.bank_id = details[0].id;
          } else {
            this.formData.bank_id = "";
          }
        } else {
          this.bank_disabled = false;
          this.formData.bank_id = "";
        }
      } else {
        this.bank_disabled = true;
        this.formData.bank_id = "";
      }
    },
    getElements(){
        Services.getTaxPayrollElements()
          .then((res) => {
            this.$store.commit("taxPayrolls/setTaxElements", res.data.data);
            if (res.data.data.branchs.length == 1) {
              this.formData.branch_id = res.data.data.branchs[0].id;
              this.branchDisabled = true;
              this.getPayments();
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    clearData() {
      this.formData.receipt_no = "";
      this.formData.date = this.today;
      this.formData.tax_type = 1;
      this.formData.title_id = "";
      this.formData.payment_method_id = "";
      this.formData.bank_id = "";
      this.formData.branch_id = "";
      this.formData.ref = null;
      this.formData.amount = null;
      this.formData.description = null;
      this.disableSubmitButton = false;
      this.bank_disabled = true;
      this.payement_methods = [];
      this.banks = [];
      this.branchDisabled = false;
    },

  },
  watch: {
    modalId(value) {
      if (value == "editTaxPayroll") {
        let id = this.dataId;
        this.clearData();
        this.getElements();
        let details = this.taxDatas.find(function(data){
            return data.id == id;
        });
        this.formData = {...details};
        this.getPayments();
        this.formData.payment_method_id = details.payment_method_id;
        this.getBanks();
        this.formData.bank_id = details.bank_id;
      }
    },
  },
};
</script>
